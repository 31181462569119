import { type FC } from 'react'
import { SubscribeForm } from '~/components/SubscribeForm'

interface CardBlockProps {
  image?: string
  header?: string
  body?: string
  imageClasses?: string
  hasSubscribe?: boolean
  items?: Array<{
    image?: string
    header?: string
    body?: string
  }>
}

export const CardBlock: FC<CardBlockProps> = ({ image, header, body, imageClasses, hasSubscribe, items }) => {
  return (
        <div className="h-full flex-col justify-center items-center gap-12 mt-8 rounded-3xl">
            <div className="h-full px-4 pt-12 pb-8 rounded-3xl border-transparent flex-col justify-center items-center gap-3 flex wrapped-card-block">
                <img className={imageClasses} src={image} alt="" />
                {hasSubscribe ? (
                    <SubscribeForm isSticky={false} />
                ) : (
                    <>
                        <p className="text-center text-white text-4xl font-bold">{header}</p>
                        <p className="text-center text-white text-base font-medium">{body}</p>
                    </>
                )}
                {items && (
                    <div className="flex flex-col">
                        {items.map((item, index) => (
                            <div key={index} style={{ background: 'rgb(34, 90, 215,0.1)' }} className="my-1 h-full px-16 pt-12 pb-8 rounded-3xl border-transparent flex-col justify-center items-center gap-3 flex">
                                {item.image && <img src={item.image} alt="" />}
                                {item.header && <p className="text-center text-white text-3xl font-bold">{item.header}</p>}
                                {item.header && <p className="text-center text-white text-base font-medium">{item.body}</p>}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
  )
}
