import { type FC } from 'react'
import { Link } from 'react-router-dom'

interface ImageCardBlockProps {
  image?: string
  body?: string
  imageClasses?: string
}

export const ImageCardBlock: FC<ImageCardBlockProps> = ({ image, body, imageClasses }) => {
  return (
    <div className="h-full flex-col justify-center items-center gap-12 p-2 rounded-3xl">
      <div className="h-full px-4 pt-12 pb-8 rounded-3xl border-transparent flex-col justify-center items-center gap-3 flex wrapped-card-block">
        <div className='rounded-3xl' style={{
          background: 'rgba(34, 90, 215, 0.1)',
          padding: '50px'
        }}>
          <img className={imageClasses} src={image} alt="" />
        </div>
        <div className="w-80 text-center text-white text-xl font-medium ">{body}</div>
        <Link to='/charity-assets/annualreport2024.pdf' download target="_blank"
          rel="noopener noreferrer">
          <div className="px-7 py-4 rounded-3xl border-2 justify-center items-center gap-2.5 inline-flex">
            <div className="text-center text-white text-base font-bold">Download Annual Report</div>
          </div>
        </Link>

      </div>
    </div>
  )
}
