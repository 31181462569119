import { type FC } from 'react'
import { Link } from 'react-router-dom'

interface SocialBlockItemProps {
  icon?: string
  text?: string
  background?: string
  shareUrl?: string
}

export const SocialBlockItem: FC<SocialBlockItemProps> = ({ icon, text, background, shareUrl = '#' }) => {
  return (
    <Link to={shareUrl} target='_blank' rel="noopener noreferrer">
      <div style={{ background }} className="h-20 md:w-72 px-2.5 rounded-md justify-center items-center gap-2.5 inline-flex cursor-pointer">
        <div className="justify-center items-center gap-2.5 flex">
          <img src={icon} alt="" />
          <div className="text-center text-white text-xl font-semibold leading-tight">{text}</div>
        </div>
      </div>
    </Link>
  )
}
