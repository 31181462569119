import { type FC } from 'react'
import { Header, WithFooter } from '~/components'
import WrappedLogo from '../img/wrapped/Ramadan-wrapped-logo.svg'
import GloveIcon from '../img/globe.png'
import { HomePageCharityImages } from '~/components/HomePageCharityImages'
import { CardBlock } from '~/components/wrapped/CardBlock'
import UsersStackedIcon from '../img/users-stacked-icon.png'
import UkFlagIcon from '../img/uk-flag-icon.png'
import UsFlagIcon from '../img/us-flag-icon.png'
import MFIcon from '../img/mf-icon.png'
import HeartPalms from '../img/palms-heart-shape-icon.png'
import IslamicReliefLogo from '../img/islamic-relief-logo-white-bg.svg'
import MuslimAidLogo from '../img/muslim-aid-logo-white-bg.svg'
import PennyAppealLogo from '../img/penny-appeal-logo-white-bg.svg'
import HeartIconYellow from '../img/heart-icon-yellow.png'
import ZakatGreenIcon from '../img/zakat-icon-green.svg'
import ZakatOrangeIcon from '../img/zakat-icon-orange.svg'
import BloodBottleIcon from '../img/blood-bottle-red.svg'
import FireworkIcon from '../img/firework-icon.png'
import FacebookIcon from '../img/facebook-icon.svg'
import LinkedInIcon from '../img/linkedin-icon.svg'
import WhatsAppIcon from '../img/whatsapp-icon.svg'
import XIcon from '../img/x-icon.svg'
import Mosque from '../img/palms-up-together.png'
import { SocialBlockItem } from '~/components/SocialBlockItem'
import { ImageCardBlock } from '~/components/ImageCardBlock'
import AnnualReportImg from '../img/annual-report-img.png'

export const WrappedHomePage: FC = () => {
  const TopCharities = [
    { image: IslamicReliefLogo, header: 'Over £2.7m' },
    { image: MuslimAidLogo, header: 'Over £1.4m' },
    { image: PennyAppealLogo, header: 'Over £1.4m' }
  ]

  const TopCauses = [
    { image: ZakatGreenIcon, header: 'Zakat', body: 'Over £11m' },
    { image: ZakatOrangeIcon, header: 'Zakat', body: 'Over £1.7m' },
    { image: BloodBottleIcon, header: 'Emergency', body: 'Over £1.3m' }
  ]

  const CardsData = [
    { image: GloveIcon, header: 'Over £15m', body: 'Worldwide Donations' },
    { image: UsersStackedIcon, header: 'Over 147K', body: 'International Donors', imageClasses: 'w-36' },
    { image: UkFlagIcon, header: 'Over £10m', body: 'UK Donations' },
    { image: UsFlagIcon, header: 'Over £2m', body: 'USA Donations' },
    { image: MFIcon, header: 'Over £2m raised', body: 'MyFridays launched!' },
    { image: HeartPalms, header: 'Top 3 Charities', items: TopCharities },
    { image: HeartIconYellow, header: 'Top 3 Causes', items: TopCauses }
  ]

  const SocialData = [
    { icon: FacebookIcon, text: 'Share', background: '#0866ff', shareUrl: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}` },
    { icon: LinkedInIcon, text: 'Share', background: '#0a66c2', shareUrl: `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}` },
    { icon: WhatsAppIcon, text: 'Share', background: '#25d366', shareUrl: `https://wa.me/?text=${encodeURIComponent(`Check this out: ${window.location.href}`)}` },
    { icon: XIcon, text: 'Share', background: 'black', shareUrl: `https://twitter.com/share?url=${encodeURIComponent(window.location.href)}` }
  ]

  return (
    <WithFooter navigation>
      <Header transparent showProfileLink />
      <div className="flex flex-col lg:justify-center items-center bg-top bg-no-repeat bg-cover mountain-sky-full">

        <div className="flex justify-center pt-32 mb-16">
          <img src={WrappedLogo} alt="" />
        </div>

        {CardsData.map((card, index) => (
          <div key={index} className='md:w-2/6 w-96'>
            <CardBlock {...card} />
          </div>
        ))}

        <div className="my-10 flex flex-col md:flex-row items-center justify-center gap-5">
          <img src={FireworkIcon} alt="" />
          <div className="text-center text-white text-3xl font-bold">Thank you everyone!</div>
        </div>
        <div className="text-white text-3xl font-medium p-5 text-center">
          Share on your socials so we can raise even more in 2025!
        </div>

        <div className="my-20 w-full md:w-auto px-5">
          <div className="md:flex grid gap-5 mb-5">
            {SocialData.slice(0, 2).map((social, index) => (
              <SocialBlockItem key={index} {...social} />
            ))}
          </div>
          <div className="md:flex grid gap-5">
            {SocialData.slice(2).map((social, index) => (
              <SocialBlockItem key={index} {...social} />
            ))}
          </div>
        </div>

        <div className='md:w-2/6 w-96'>
          <ImageCardBlock
            image={AnnualReportImg}
            body="Checkout the 2024 Annual Report for more stats!"
          />
        </div>

        <div className='md:w-2/6 w-96'>
          <CardBlock image={Mosque} hasSubscribe />
        </div>

        <HomePageCharityImages />
      </div>
    </WithFooter>
  )
}
