import { type FC } from 'react'
import GoogleIcon from '../../../img/google-icon.png'

interface GoogleReviewsProps {
  rating?: number
  reviewsCount?: number
}

export const GoogleReviews: FC<GoogleReviewsProps> = ({ rating, reviewsCount }) => {
  return (
    <>
      <div className="flex-col justify-start items-center gap-3.5 flex">
        <div className="justify-start items-center gap-3.5 inline-flex">
          <img className="w-8 h-8" src={GoogleIcon} alt="" />
          <div className='text-xl'>
            <span className="text-white  font-bold  leading-snug">{rating}</span>
            <span style={{ color: '#e7a90b' }} className="font-bold leading-snug">★</span>
            <span className="text-white  font-bold  leading-snug"> </span>
            <span className="text-white  font-medium  leading-snug">{reviewsCount} reviews</span></div>
        </div>
      </div>
    </>
  )
}
