import { type FC } from 'react'
import Mosque from '../../img/palms-up-together.png'
import PalmsIcon from '../../img/palms-up-together1.png'
import UsersStackedIcon from '../../img/users-stacked-icon.png'
import { RamadanBlock } from './RamadanBlock'
import { GoogleReviews } from './GoogleReviews'
import { CardBlock } from './CardBlock'

export const WrappedHome: FC = () => {
  return (
        <div className='p-4'>
            <div>
                <GoogleReviews rating={4.9} reviewsCount={1.377} />
            </div>
            <div className='flex justify-center mt-8'>
                <RamadanBlock isAnimated={true} mainTitle='Unwrap Ramadan 2024' />
            </div>

            <div>
                <CardBlock image={Mosque} hasSubscribe={true} />
            </div>

            <div>
                <CardBlock hasSubscribe={false} image={PalmsIcon} header='Over £60m' body='Raised with MyTenNights' />
            </div>

            <div>
                <CardBlock hasSubscribe={false} imageClasses='w-36' image={UsersStackedIcon} header='Over 200K' body='Donors have automated with MyTenNights' />
            </div>
        </div>
  )
}
