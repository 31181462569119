export class Navigation {
  // eslint-disable-next-line @typescript-eslint/class-literal-property-style
  static get home (): string {
    return '/'
  }

  static readonly wrapped: string = '/wrapped2024'

  // eslint-disable-next-line @typescript-eslint/class-literal-property-style
  static partnerLanding (partnerName: string = ':partnerName'): string {
    return `/partner/${partnerName}`
  }

  // Allow partner prefix in the route to allow opening MTN site outside in-app browser which can redirect to partner landing page rather than the MTN homepage
  static partnerCharitySelection (): string {
    return `${this.partnerLanding()}${this.charitySelection()}`
  }

  static charitySelection (): string {
    return '/donation/charity'
  }

  static partnerCharityCauses (): string {
    return `${this.partnerLanding()}${this.charityCauses()}`
  }

  static charityCauses (): string {
    return '/donation/causes'
  }

  static partnerDetails (): string {
    return `${this.partnerLanding()}${this.details()}`
  }

  static details (): string {
    return '/donation/details'
  }

  static partnerPayment (): string {
    return `${this.partnerLanding()}${this.payment()}`
  }

  static payment (): string {
    return '/donation/payment'
  }

  static prefixPartnerApplicableRoute (route: string, partnerName: string): string {
    return `${partnerName ? this.partnerLanding(partnerName) : ''}${route}`
  }
}
