import { type FC } from 'react'
import Lottie from 'react-lottie-player'
import animationData from './../animations/fireworks.json'
import WrappedLogo from '../../../img/wrapped/Ramadan-wrapped-logo.svg'
import { Navigation } from '~/components'
import { Link } from 'react-router-dom'

interface RamadanBlockProps {
  mainTitle?: string
  isAnimated?: boolean
}

export const RamadanBlock: FC<RamadanBlockProps> = ({ mainTitle, isAnimated }) => {
  return (
        <>
            {isAnimated && <Lottie loop animationData={animationData} play className='absolute pt-10' style={{ width: 300, height: 200 }} />}
            <div className="h-full w-full flex-col justify-center items-center gap-12 pt-2">
                <div style={{ border: '1px solid rgba(255, 255, 255, 0.10)' }} className="bg-wrapped-color-block-main h-full px-4 pt-11 pb-7 rounded-3xl border-transparent flex-col justify-center items-center gap-7 flex">
                    <img src={WrappedLogo} alt="" className='p-5'/>
                    <Link className="mb-4 hover:text-gm-yellow text-white" to={Navigation.wrapped}>
                        <div className="border-wrapped-button bg-transparent cursor-pointer w-80 px-7 py-4 rounded-full border-2 justify-center items-center gap-2.5 inline-flex">
                            <div className="text-center text-white text-base font-bold">{mainTitle}</div>
                        </div>
                    </Link>

                </div>
            </div>
        </>
  )
}
