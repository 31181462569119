import { type FC } from 'react'
import { GetDiffBetweenCampaignDate } from './Split'
import Lottie from 'react-lottie-player'
import animationData from '../../components/wrapped/animations/stars.json'

export const LandingPageContent: FC = () => {
  const diff = GetDiffBetweenCampaignDate()
  return <>
    <h1 className="headline text-center font-semibold tracking-wide text-white mt-20">
      Never Miss
    </h1>
    <h1 className="headline text-center font-semibold tracking-wide text-white">
      Laylatul Qadr
    </h1>
    <div>
      <Lottie loop animationData={animationData} play style={{ width: 300, height: 40 }} />
    </div>
    <p className="text-mtn-white leading-7 text-xl md:text-xl leading-4 lin w-80 text-center">
      {`Automate your donations ${diff > -19 ? 'across' : 'over the last 10 nights of'}  Ramadan`}</p>
  </>
}
export const HomeHeadline: FC = () => {
  return <section className={'flex px-4 mt-28 md:mt-0 flex-col w-11/12 sm:w-3/4 items-center'}>
    <LandingPageContent />
  </section>
}
