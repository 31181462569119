import { type FC } from 'react'
import homePageCharityImage1 from './../pages/home/home-page-charity1.png'
import homePageCharityImage2 from './../pages/home/home-page-charity2.png'
import homePageCharityImage3 from './../pages/home/home-page-charity3.png'
import homePageCharityImage4 from './../pages/home/home-page-charity4.png'
import homePageCharityImage5 from './../pages/home/home-page-charity5.png'

export const HomePageCharityImages: FC = () => {
  const charityImages = [
    [
      { src: homePageCharityImage1, alt: 'home page charity logo 1', className: 'h-11 md:h-12 object-contain' },
      { src: homePageCharityImage2, alt: 'home page charity logo 2', className: 'h-6 md:h-7 object-contain' },
      { src: homePageCharityImage3, alt: 'home page charity logo 3', className: 'h-6 md:h-7 object-contain' }
    ],
    [
      { src: homePageCharityImage4, alt: 'home page charity logo 4', className: 'h-5 md:h-6 object-contain' },
      { src: homePageCharityImage5, alt: 'home page charity logo 5', className: 'h-10 md:h-12 object-contain' }
    ]
  ]

  return (
    <div className="mt-10 mb-16 md:my-10 gap-y-8 md:gap-x-6 px-8 flex flex-col md:flex-col lg:flex-row justify-between">
      {charityImages.map((row, rowIndex) => (
        <div key={rowIndex} className="flex flex-row gap-x-4 md:gap-x-8 items-center justify-center">
          {row.map((image, imageIndex) => (
            <img key={imageIndex} src={image.src} alt={image.alt} className={image.className} />
          ))}
        </div>
      ))}
    </div>
  )
}
