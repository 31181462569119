import { type FC } from 'react'

interface UserProfileIconProps {
  isScrolled?: boolean
  classes?: string
}

export const UserProfileIcon: FC<UserProfileIconProps> = ({ isScrolled, classes }) => {
  const fill = isScrolled ? 'black' : 'white'

  return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes}>
            <path id="profile-user 1 (Traced)" fill-rule="evenodd"
                clip-rule="evenodd" d="M8.63288 0.524763C7.43701 0.649622 6.41889 0.934019 5.38941 1.43067C4.40836 1.90397 3.69338 2.40061 2.92683 3.14114C2.0509 3.98735 1.48412 4.76118 0.964972 5.81989C0.49977 6.76847 0.235492 7.61824 0.0690719 8.70067C-0.023024 9.2995 -0.023024 10.7038 0.0690719 11.3027C0.235492 12.3851 0.49977 13.2349 0.964972 14.1834C1.90865 16.1078 3.39323 17.5919 5.3172 18.5345C6.25032 18.9916 7.07881 19.2525 8.10573 19.4127C8.8515 19.5291 10.1485 19.5291 10.8943 19.4127C11.9212 19.2525 12.7497 18.9916 13.6828 18.5345C15.6068 17.5919 17.0914 16.1078 18.035 14.1834C18.5002 13.2349 18.7645 12.3851 18.9309 11.3027C19.023 10.7038 19.023 9.2995 18.9309 8.70067C18.7645 7.61824 18.5002 6.76847 18.035 5.81989C17.0914 3.89553 15.6068 2.41139 13.6828 1.46888C12.7512 1.01256 11.8977 0.743962 10.9008 0.593493C10.3345 0.507999 9.13998 0.471794 8.63288 0.524763ZM10.1489 3.40405C11.1504 3.61612 11.9796 4.29895 12.391 5.25035C12.5672 5.6579 12.6336 6.00534 12.6311 6.50756C12.6267 7.38733 12.3037 8.1337 11.6619 8.74676C11.3167 9.0764 10.9517 9.3024 10.5296 9.44789C8.61533 10.1075 6.58647 8.81155 6.37785 6.79583C6.21373 5.20973 7.27631 3.74347 8.83492 3.40543C9.15177 3.3367 9.8276 3.33599 10.1489 3.40405ZM12.1212 11.2584C13.0853 11.5261 13.8077 12.1323 14.2186 13.0183C14.5892 13.8174 14.609 14.5929 14.2715 15.0858C13.9923 15.4934 12.9996 16.1546 12.1212 16.5179C10.5531 17.1664 8.57071 17.181 6.97177 16.5558C6.09795 16.2141 4.96217 15.4734 4.71611 15.0847C4.42621 14.6267 4.40873 14.0258 4.66372 13.2828C5.03835 12.1912 6.04255 11.3685 7.2134 11.1941C7.3361 11.1759 8.42363 11.1643 9.63013 11.1684C11.771 11.1756 11.8309 11.1778 12.1212 11.2584Z"
                fill={fill} />
        </svg>
  )
}
